<template>
  <page-view :title="title">
    <div class="user_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>登录名</span>
            <a-input placeholder="登录名" class="filter_input" v-model.trim="queryParam.username" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>手机号</span>
            <a-input placeholder="手机号" class="filter_input" v-model.trim="queryParam.mobile" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>支付id</span>
            <a-input placeholder="支付id" class="filter_input" v-model.trim="queryParam.payAccount" />
          </div>
        </a-col>
        <a-col :lg="3" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="user_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="logo" slot-scope="text">
          <img :src="text" style="width: 45px;height: 45px;" />
        </template>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)" @click="copyInfo(record.uid)">
              {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.showid">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="alipayAccount" slot-scope="text">
          <span v-if="text">{{ text }}</span>
          <span v-else>-</span>
        </template>
        <template slot="alipayName" slot-scope="text">
          <span v-if="text">{{ text }}</span>
          <span v-else>-</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button type="link" style="left: 10px;" @click="showWithdrawModal(record, true)">余额提现</a-button>
        </template>
      </s-table>
    </div>
    <a-modal title="资金提现" :width="560" :visible="withdraw.show" :maskClosable="false" @cancel="showWithdrawModal({}, false)" :footer="null">
      <!-- 没有支付宝 -->
      <a-form>
        <a-form-item label="到账账号" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
          <a-input type="text" v-model="withdraw.alipayAccount" class="alipay_input" autocomplete="false" placeholder="请输入支付宝账号"></a-input>
        </a-form-item>
        <a-form-item label="真实姓名" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
          <a-input v-model="withdraw.alipayName" type="text" class="alipay_input" autocomplete="false" placeholder="请输入支付宝真实姓名"></a-input>
        </a-form-item>
        <a-form-item label="申请原因" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
          <a-input v-model="withdraw.balanceRefundRemark" type="text" class="alipay_input" autocomplete="false" placeholder="请输入申请原因"></a-input>
        </a-form-item>
        <a-form-item label="手机" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
          <a-input :value="withdraw.mobile" disabled type="text" class="alipay_input"></a-input>
        </a-form-item>
        <a-form-item label="验证码" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }" >
          <a-space>
            <a-input
              v-model="withdraw.code"
              class="captcha_input"
              type="text"
              placeholder="手机验证码"
            ></a-input>
            <a-button
              class="captcha_btn"
              tabindex="-1"
              :disabled="stateOld.smsSendBtn"
              @click.stop.prevent="getOldMobileCaptcha"
              v-text="
                (!stateOld.smsSendBtn && '获取验证码') || stateOld.time + ' s'
              "
            ></a-button>
          </a-space>
        </a-form-item>
        <a-form-item label="提现金额" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
          <a-input-number
            class="alipay_input input_number"
            autocomplete="false"
            v-model="withdraw.balance"
            :placeholder="`可提现金额￥${record.balanceWithdrawabl}元`"
            :precision="2"
          ></a-input-number>
          <span class="withdrawabl" @click="withdrawAll">全部</span>
        </a-form-item>
      </a-form>
      <a-button style="margin-left:129px" type="primary" htmlType="submit" @click.stop.prevent="withdrawOk">立即申请</a-button>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';
import { sipRoleUpdateUser, accountRefundBalance, accountList, billAdminList, sipUserUpdate, sipUserByUid, createWithdraw, sipSms } from '@api';
import { formatDate, roleCodeToName, billType } from '@/utils/util';


export default {
  data() {
    return {
      withdraw: { show: false }, // 不做了 提现
      record: {},
      billQueryId: '',
      billListVisible: false,
      billListData: [],
      billPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      refundData: {
        uid: '',
        amount: '',
        comment: ''
      },
      refundShow: false,
      rolesList: [],

      edit: { show: false },
      queryParam: {},
      stateOld: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: "#FF0000",
      },
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '支付id',
          dataIndex: 'payAccount'
        },
        {
          title: '登录名',
          dataIndex: 'username'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '余额',
          dataIndex: 'balance'
        },
        {
          title: '申请的余额',
          dataIndex: 'balanceRefundIn'
        },
        {
          title: '总共成功提现的余额',
          dataIndex: 'balanceRefundAll'
        },
        {
          title: '申请备注',
          dataIndex: 'balanceRefundRemark'
        },
        {
          title: '申请的时间',
          dataIndex: 'balanceRefundCashTime',
          customRender: formatDate,
        },
        {
          title: '支付宝登录名',
          dataIndex: 'alipayAccount',
          scopedSlots: { customRender: 'alipayAccount' }
        },
        {
          title: '支付宝真实姓名',
          dataIndex: 'alipayName',
          scopedSlots: { customRender: 'alipayName' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate,
          sorter: (a, b) => a.createTime - b.createTime
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        return accountList('admin', Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => (item.showid = false));
          return result.data;
        });
      }
    };
  },
  created() {
    //修改角色列表
    const array = [];
    for (let key in roleCodeToName) {
      array.push({ name: roleCodeToName[key].name, value: key });
    }
    this.rolesList = array;
  },
  components: {
    PageView,
    STable
  },
  methods: {
    getOldMobileCaptcha(e) {
      e.preventDefault();
      const { stateOld, withdraw } = this;
      const mobile = withdraw.mobile
      stateOld.smsSendBtn = true;
      const interval = window.setInterval(() => {
        if (stateOld.time-- <= 0) {
          stateOld.time = 60;
          stateOld.smsSendBtn = false;
          window.clearInterval(interval);
        }
      }, 1000);
      const hide = this.$message.loading("验证码发送中..", 0);
      sipSms(mobile, 2)
        .then((res) => {
          if (res.success) {
            setTimeout(hide, 2500);
            this.$notification["success"]({
              message: "提示",
              description: "验证码获取成功",
              duration: 8,
            });
          } else {
            setTimeout(hide, 2500);
            this.$notification["error"]({
              message: "错误",
              description: res.msg,
              duration: 4,
            });
          }
        })
        .catch((err) => {
          setTimeout(hide, 1);
          clearInterval(interval);
          stateOld.time = 60;
          stateOld.smsSendBtn = false;
          this.requestFailed(err);
        });
        
    },
    withdrawAll() {
      this.withdraw.balance = this.record.balance;
    },
    showWithdrawModal(record, flag) {
      this.record = JSON.parse(JSON.stringify(record))
      if (flag) {
        console.log('[this.record] ---> ', this.record)
        const data = {
          ...record,
          show: true,
          balanceRefundRemark: '不做了',
          code: undefined,
        }
        this.withdraw = data;
      } else {
        this.withdraw = { show: false }
      }
    },
    withdrawOk() {
      const withdraw = this.withdraw;
      if (!withdraw.alipayAccount) return this.$message.error('请填写支付宝账号')
      if (!withdraw.alipayName) return this.$message.error('请填写支付宝实名姓名')
      if (!withdraw.code) return this.$message.error('请填写手机验证码')
      // 没有支付宝的情况
      const data = {
        uid: withdraw.uid,
        balance: withdraw.balance,
        balanceRefundRemark: withdraw.balanceRefundRemark,
        alipayAccount: withdraw.alipayAccount.trim(),
        alipayName: withdraw.alipayName.trim(),
        code: withdraw.code.trim()
      };
      createWithdraw(data).then((result) => {
        if (result.success) {
          this.$refs.table.refresh(false);
          //关闭
          this.showWithdrawModal({}, false);
          //提示
          this.$message.success('申请成功');
        } else {
          this.$message.error(result.msg);
        }
      })
    },
    //资金变动
    showBillList(record) {
      this.billListVisible = true;
      this.billListData = []; //每次打开弹框清空数据
      this.billPage.pageNum = 1;
      this.billQueryId = record.uid;
      this.getBillList();
    },
    getBillList() {
      const { billPage, billQueryId } = this;
      let params = {
        uid: billQueryId,
        pageNum: billPage.pageNum,
        pageSize: billPage.pageSize
      };
      billAdminList(params).then((res) => {
        if (res.success) {
          this.billListData = res.data.list;
          this.billPage = res.data.page;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    billPageChange(page) {
      this.billPage.pageNum = page;
      this.getBillList();
    },
    closeBillList() {
      this.billListVisible = false;
    },
    handleMouseover(record) {
      record.showid = true;
    },
    handleMouseout(record) {
      record.showid = false;
    },
    closeRefund() {
      this.refundData = {};
      this.refundShow = false;
    },
    refund(uid) {
      this.refundData.uid = uid;
      this.refundShow = true;
    },
    chanegEditBlocked() {
      this.edit.blocked = !this.edit.blocked;
    },
    handleRefundOk() {
      if (!this.refundData.amount) {
        return this.$message.error('退款金额必填');
      }
      accountRefundBalance(this.refundData).then((res) => {
        if (res.success) {
          this.$message.success('退款成功');
          this.refundData = {};
          this.refundShow = false;
        } else {
          this.$message.error('退款失败');
        }
      });
    },
    // 重置
    resetQuery() {
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },

    update(record) {
      //record.show = true;
      // this.edit = JSON.parse(JSON.stringify(record));
      sipUserByUid(record.uid).then((res) => {
        if (res) {
          res.show = true;
          this.edit = JSON.parse(JSON.stringify(res));
          this.edit.roles = this.edit.roles.filter((item) => {
            return item !== 'NORMAL';
          });
        } else {
          this.$message.error('出错了');
        }
      });
    },

    handleCancel() {
      this.edit = { show: false };
    },
    handleOk() {
      const edit = this.edit;
      //修改前三个
      sipUserUpdate({
        id: edit.id,
        username: edit.username,
        mobile: edit.mobile,
        email: edit.email,
        blocked: edit.blocked
      }).then((res) => {
        if (res.success) {
          // 填了role才修改角色
          if (edit.roles) {
            const roleIds = [];
            edit.roles.map((it) => {
              roleIds.push(roleCodeToName[it].id);
            });
            sipRoleUpdateUser({ userIds: [edit.id], roleIds: roleIds }).then((res) => {
              if (res.success) {
                this.$message.success('成功');
                this.$refs.table.refresh(false);
                this.edit = { show: false };
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            //没有role直接提示
            this.$message.success('成功');
            this.$refs.table.refresh(false);
            this.edit = { show: false };
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.user_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.user_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.input_group {
  display: flex;
  margin-bottom: 24px;
  // align-items: center;
  span {
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.top5 {
  width: 320px;
  height: 32px;
}
.pagin {
  margin-top: 24px;
  margin-right: 24px;
  text-align: right;
}

.alipay_input {
  width: 320px;
  height: 32px;
}
.input_number {
  position: relative;
}
.withdrawabl {
  color: #1890ff;
  position: absolute;
  right: 16px;
  top: -10px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
}
.account_box {
  display: flex;
  justify-content: space-between;
}
.withdraw_now {
  color: #1890ff;
  padding: 0 10px;

  cursor: pointer;
}

.captcha_input {
  width: 210px;
  height: 32px;
}
.captcha_btn {
  width: 102px;
  height: 32px;
}
</style>
